import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-s-m-p-table',
  templateUrl: './s-m-p-table.component.html',
  styleUrls: ['./s-m-p-table.component.scss']
})
export class SMPTableComponent implements OnInit {
  @Input() redirection;
  @Input() redirectionAfterSave;
  @Input() is_allow_private = false;
  @Input() remove_submit = false;
  @Input() recepient_only = false;
  @Input() disable_form = false;
  @Input() isNew: boolean = false;
  @Input() LineItemAdded:any;
  constructor() { }

  ngOnInit() {
  }

}
