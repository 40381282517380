import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from 'sr-fe-core';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./module/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'purchase-order',
        loadChildren: () => import('./module/purchase-order/purchase-order.module').then(m => m.PurchaseOrderModule),
      },
      {
        path: 'invoice',
        loadChildren: () => import('./module/invoice/invoice.module').then(m => m.InvoiceModule),
      },
      {
        path: 'asn',
        loadChildren: () => import('./module/asn/asn.module').then(m => m.AsnModule),
      },
      {
        path: 'supplier-onboarding',
        loadChildren: () => import('./module/supplier-onboarding/supplier-onboarding.module').then(m => m.SupplierOnboardingModule),
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SrFeSupplierMgmtRoutingModule { }
